import React, { useState } from "react";
import PropTypes from "prop-types";
import { setJwtToken } from "./utils/utils";

export const RootContext = React.createContext();

const RootContextProvider = ({ children }) => {
  const [user, setStateUser] = useState();
  const [account, setStateAccount] = useState();
  const [token, setStateToken] = useState();
  const [isTokenLoaded, setIsTokenLoaded] = useState();
  const [businesses, setStateBusinesses] = useState();
  const [groupsContext, setStateGroupsContext] = useState();
  const [templatesContext, setStateTemplatesContext] = useState();
  const [personalTemplatesContext, setStatePersonalTemplatesContext] =
    useState();
  const [IsScrapingWebsite, setIsScrapingWebsite] = useState(false);
  const [IsAllowedToAccess, setIsAllowedToAccess] = useState(false);

  const setToken = (newToken, shouldSave = false) => {
    if (shouldSave && newToken !== null) {
      window.localStorage.setItem("userToken", newToken);
      setIsTokenLoaded(true);
    } else if (shouldSave && newToken === null) {
      window.localStorage.removeItem("userToken");
      window.localStorage.removeItem("account");
    }
    setJwtToken(newToken);
    setStateToken(newToken);
    setIsTokenLoaded(true);
  };

  const setBusinesses = (state) => {
    setStateBusinesses(state);
  };

  const setAccount = (account) => {
    window.localStorage.setItem("account", JSON.stringify(account));
    setStateAccount(account);
  };

  const setGroupsContext = (state) => {
    setStateGroupsContext(state);
  };

  const setTemplatesContext = (state) => {
    setStateTemplatesContext(state);
  };

  const setPersonalTemplatesContext = (state) => {
    setStatePersonalTemplatesContext(state);
  };

  const setScrapingWebsite = (state) => {
    setIsScrapingWebsite(state);
  };

  const setAccountAccess = (account) => {
    let cancelSubDate = null;
    let currentDate = new Date();
    if (account.CancelSubAt) {
      cancelSubDate = new Date(account.CancelSubAt * 1000);
    }
    if (account.IsPaying) {
      setIsAllowedToAccess(true);
    } else if (
      !account.IsPaying &&
      cancelSubDate &&
      currentDate <= cancelSubDate
    ) {
      setIsAllowedToAccess(true);
    } else {
      setIsAllowedToAccess(false);
    }
  };

  const value = {
    user,
    setUser: setStateUser,
    account,
    setAccount,
    setStateAccount,
    token,
    setToken,
    isTokenLoaded,
    setIsTokenLoaded,
    businesses,
    setBusinesses,
    groupsContext,
    setGroupsContext,
    templatesContext,
    setTemplatesContext,
    personalTemplatesContext,
    setPersonalTemplatesContext,
    setScrapingWebsite,
    IsScrapingWebsite,
    setAccountAccess,
    IsAllowedToAccess,
  };
  return <RootContext.Provider value={value}>{children}</RootContext.Provider>;
};

RootContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default RootContextProvider;
