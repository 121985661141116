import "react-app-polyfill/stable";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Root from "./Root";
import RootContextProvider from "./RootContextProvider";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";
import ErrorHandler from "./FrontErrorHandler";
import { ErrorBoundary } from "react-error-boundary";
import "./assets/fonts/FilsonProBold/font.woff";
import Loader from "./components/loader/Loader";
import "./i18n";

ReactDOM.render(
  <ErrorBoundary
    FallbackComponent={ErrorHandler}
    onReset={() => {
      // reset the state of your app so the error doesn't happen again
    }}
  >
    <Suspense
      fallback={
        <div className="loader">
          <Loader />
        </div>
      }
    >
      <Router>
        <RootContextProvider>
          <Root />
        </RootContextProvider>
      </Router>
    </Suspense>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
