import React, { useState, useEffect } from "react";
import { Input, message, Button, Row, Col } from "antd";
import { Link, useLocation } from "react-router-dom";
// import { extractParams } from "../../utils/utils";
import { reset } from "../../../utils/auth";
import qs from "query-string";

import PasswordStrengthBar from "react-password-strength-bar";
import { useTranslation } from "react-i18next";
import CarouselLogin from "../signup/Carousels/CarouselLogin";
import logoLR from "../../../assets/logoPlatforms/logo-new-lr.svg";

const ResetPasswordV2 = ({ props }) => {
  const location = useLocation();
  const [loadingWhiteLabel, setLoadingWhiteLabel] = useState(true);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [errorDisplay, setErrorDisplay] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successDisplay, setSuccessDisplay] = useState(false);
  const [pwScore, setPwScore] = useState(0);
  const [isWhiteLabel, setIsWhiteLabel] = useState(false);
  const [whiteLabelInfo, setWhiteLabelInfo] = useState();

  const { t } = useTranslation(["auth", "common"]);

  useEffect(() => {
    const getParam = async () => {
      let params = qs.parse(location.search);
      if (params.id) {
        setToken(params.id);
      } else {
        message.config({
          maxCount: 1,
        });
        message.warning("Lien invalide");
      }
    };
    const getWhiteLabelInfo = async () => {
      let data = window.localStorage.getItem("whitelabel");
      if (data) {
        setIsWhiteLabel(true);
        console.log(JSON.parse(data));
        setWhiteLabelInfo(JSON.parse(data));
      }
      setLoadingWhiteLabel(false);
    };
    getWhiteLabelInfo();
    getParam();
    // eslint-disable-next-line
  }, []);

  const handleOnSubmit = async () => {
    try {
      if (token && password) {
        if (pwScore > 2) {
          setLoading(true);
          const resetPass = await reset(token, password);
          if (resetPass.message === "Password successfully reset") {
            setSuccessDisplay(true);
            setLoading(false);
          } else {
            console.error(resetPass.err);
            setErrorMessage(resetPass.err);
            setErrorDisplay(true);
            setLoading(false);
          }
        } else {
          message.error("Votre mot de passe n'est pas assez sécurisé");
        }
      } else {
        message.error("Please provide a valid password.");
      }
    } catch (err) {
      message.error(err.message);
    }
  };
  return isWhiteLabel ? (
    <div className="signup-container-v2">
      <div className="block-right-content">
        <div className="welcome-form">
          {" "}
          <div className="heading-signup-new">
            <span>{t("auth:new-password")}</span>
          </div>
          <div className="signup-local">
            {!errorDisplay && !successDisplay && (
              <>
                <Input.Password
                  style={{ marginBottom: 0 }}
                  autoFocus
                  type="password"
                  placeholder="******"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
                <PasswordStrengthBar
                  className="password-bar"
                  password={password}
                  onChangeScore={(score) => {
                    setPwScore(score);
                  }}
                  scoreWords={[
                    t("common:form.password-strength.veryweak"),
                    t("common:form.password-strength.weak"),
                    t("common:form.password-strength.mid"),
                    t("common:form.password-strength.good"),
                    t("common:form.password-strength.verygood"),
                  ]}
                  shortScoreWord={t("common:form.password-strength.tooshort")}
                />
                <Button
                  className="signup-button-valid"
                  type="primary"
                  disabled={loading}
                  onClick={handleOnSubmit}
                  icon="arrow-right"
                >
                  {t("common:change-password")}
                </Button>
              </>
            )}
            {successDisplay && (
              <>
                <div className="success-reset">
                  <span
                    role="img"
                    aria-label="emoji"
                    style={{ marginRight: "10px" }}
                  >
                    ✅
                  </span>
                  <span>{t("auth:password-edited")}</span>
                </div>
                <Button
                  className="signup-button-valid"
                  type="primary"
                  disabled={loading}
                  icon="arrow-right"
                >
                  <Link to="/signin">{t("common:button.login-now")}</Link>
                </Button>
              </>
            )}
            {errorDisplay && (
              <>
                <div className="success-reset">
                  <span role="img" aria-label="emoji">
                    ❌{" "}
                  </span>
                  Oh-ho! {errorMessage}
                  <span>
                    <br />
                    {t("auth:reset-password-fail")}
                  </span>
                </div>
                <Button
                  className="signup-button-valid"
                  type="primary"
                  disabled={loading}
                  icon="arrow-right"
                >
                  <Link to="/forgot-password">
                    {t("auth:receive-new-link")}
                  </Link>
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="logo-block-right">
          {whiteLabelInfo && whiteLabelInfo.AppLogo ? (
            <img src={whiteLabelInfo.AppLogo} alt="logo app" width="190px" />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="new-signup login">
      <Row>
        <Col xl={12} lg={12} md={24} sm={24}>
          <div className="promo-section">
            <CarouselLogin />
            <div className="promo-footer">{t("auth:trusted-by")}</div>
          </div>
        </Col>
        <Col xl={12} lg={12} md={24} sm={24}>
          <div className="signup-container-v2">
            <div className="welcome-form">
              {" "}
              <div className="heading-signup-new">
                <div className="logo">
                  {" "}
                  <img
                    src={logoLR}
                    alt="logo local ranker"
                    width="160px"
                    style={{ marginBottom: "60px" }}
                  />
                </div>
                <span>{t("auth:new-password")}</span>
              </div>
              <div className="signup-local">
                {!errorDisplay && !successDisplay && (
                  <>
                    <Input.Password
                      style={{ marginBottom: 0 }}
                      autoFocus
                      type="password"
                      placeholder="******"
                      required
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <PasswordStrengthBar
                      className="password-bar"
                      password={password}
                      onChangeScore={(score) => {
                        setPwScore(score);
                      }}
                      scoreWords={[
                        t("common:form.password-strength.veryweak"),
                        t("common:form.password-strength.weak"),
                        t("common:form.password-strength.mid"),
                        t("common:form.password-strength.good"),
                        t("common:form.password-strength.verygood"),
                      ]}
                      shortScoreWord={t(
                        "common:form.password-strength.tooshort"
                      )}
                    />
                    <Button
                      className="signup-button-valid"
                      type="primary"
                      disabled={loading}
                      onClick={handleOnSubmit}
                    >
                      {t("common:button.change-password")}
                    </Button>
                  </>
                )}
                {successDisplay && (
                  <>
                    <div className="success-reset">
                      <span role="img" aria-label="emoji">
                        ✅
                      </span>
                      <span>{t("auth:password-edited")}</span>
                    </div>
                    <Button
                      className="signup-button-valid"
                      type="primary"
                      disabled={loading}
                    >
                      <Link to="/signin">{t("common:button.login-now")}</Link>
                    </Button>
                  </>
                )}
                {errorDisplay && (
                  <>
                    <div className="success-reset">
                      <span role="img" aria-label="emoji">
                        ❌{" "}
                      </span>
                      Oh-ho! {errorMessage}
                      <span>
                        <br />
                        {t("auth:reset-password-fail")}
                      </span>
                    </div>
                    <Button
                      className="signup-button-valid"
                      type="primary"
                      disabled={loading}
                    >
                      <Link to="/forgot-password">
                        {t("auth:receive-new-link")}
                      </Link>
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ResetPasswordV2;
