import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { RootContext } from "./RootContextProvider";
// import Signinv2 from "./components/Auth/login/Loginv2.jsx";
// import Signupv2 from "./components/Auth/signup/Signupv2.jsx";
// import AccountValidation from "../src/components/Auth/accountValidation/AccountValidation";
import AccountValidationV2 from "./components/Auth/accountValidation/AccountValidationV2";
// import Forgot from "../src/components/forgotPassword/ForgotPassword";
// import ResetPassword from "../src/components/forgotPassword/ResetPassword";
import Loader from "./components/loader/Loader";
import { hotjar } from "react-hotjar";

import {
  defineWhiteLabelVar,
  // updateColorsWhiteLabel,
  IsJsonString,
} from "./utils/utils";
import { Helmet } from "react-helmet";
import favicon from "./assets/icons/favicon-localranker.png";
import SignupV3 from "./components/Auth/signup/SignupV3.jsx";
import LoginV3 from "./components/Auth/login/LoginV3.jsx";
import ForgotV2 from "./components/Auth/forgotPassword/ForgotPasswordV2";
import ResetPasswordV2 from "./components/Auth/forgotPassword/ResetPasswordV2";

const App = lazy(() => import("../src/AppGlobal/App.jsx"));

class Root extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, isWhiteLabel: false };
  }
  componentWillMount() {
    const { setToken } = this.context;
    const localToken = window.localStorage.getItem("userToken");
    setToken(localToken);
  }
  componentDidMount() {
    const handleWhiteLabel = async () => {
      try {
        // this.setState({ loading: true });
        let localSettingsWL = window.localStorage.getItem("whitelabel");
        if (!localSettingsWL) {
          console.log("no token found");
          let isWhiteLabel = await defineWhiteLabelVar(
            window.location.host,
            window.location.origin
          );
          if (isWhiteLabel) {
            this.setState({ isWhiteLabel: true });
          }
          this.setState({ loading: false });
        } else {
          if (IsJsonString(localSettingsWL)) {
            let parsed = JSON.parse(localSettingsWL);
            if (parsed && parsed.AccountID) {
              console.log("token found");
              // refresh LS
              let isWhiteLabel = await defineWhiteLabelVar(
                window.location.host,
                window.location.origin
              );
              if (isWhiteLabel) {
                this.setState({ isWhiteLabel: true });
              }
              this.setState({ loading: false });
              // this.setState({ isWhiteLabel: true });
              // localSettingsWL = JSON.parse(localSettingsWL);
              // await updateColorsWhiteLabel(parsed);
              // this.setState({ loading: false });
            } else {
              console.log("invalid format ls");
              let isWhiteLabel = await defineWhiteLabelVar(
                window.location.host,
                window.location.origin
              );
              if (isWhiteLabel) {
                this.setState({ isWhiteLabel: true });
              } else {
                window.localStorage.removeItem("whitelabel");
              }
              this.setState({ loading: false });
            }
          } else {
            console.log("invalid format ls");
            let isWhiteLabel = await defineWhiteLabelVar(
              window.location.host,
              window.location.origin
            );
            if (isWhiteLabel) {
              this.setState({ isWhiteLabel: true });
            } else {
              window.localStorage.removeItem("whitelabel");
            }
            this.setState({ loading: false });
          }
        }
      } catch (e) {
        console.log(e);
      }
    };
    handleWhiteLabel();
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      // dev code
    } else {
      hotjar.initialize(2082213, 6);
    }
  }

  render() {
    const { token, isTokenLoaded } = this.context;
    return !this.state.loading ? (
      <>
        {this.state.isWhiteLabel ? (
          <Helmet>
            <title>
              {JSON.parse(localStorage.getItem("whitelabel")).MetaTitle}
            </title>
            <meta
              name="description"
              content={
                JSON.parse(localStorage.getItem("whitelabel")).MetaDescription
              }
            />
            <link
              rel="icon"
              href={JSON.parse(localStorage.getItem("whitelabel")).Favicon}
            />
          </Helmet>
        ) : (
          <Helmet>
            <title>
              Localranker | Plateforme de référencement local et gestion d'avis
              clients
            </title>
            <meta
              name="description"
              content="Localranker est une plateforme aider les entreprises à développer leur SEO local. L’outil n°1 pour améliorer votre visibilité locale et e-réputation."
            />
            <link rel="icon" href={favicon} />
          </Helmet>
        )}
        <Switch>
          <Route
            path="/validation/:id"
            exact
            strict
            component={AccountValidationV2}
          />
          <Route path="/validation" component={AccountValidationV2} />
          <Route
            path="/forgot-password"
            exact
            strict
            render={(props) => (
              <Suspense fallback={null}>
                <ForgotV2 />
              </Suspense>
            )}
          />
          <Route
            path="/reset-password/:id"
            exact
            strict
            component={ResetPasswordV2}
          />
          <Route
            path="/reset-password"
            render={(props) => (
              <Suspense fallback={null}>
                <ResetPasswordV2 />
              </Suspense>
            )}
          />
          {/* // component={ResetPassword} /> */}
          <Route
            path="/signin"
            render={(props) =>
              !token ? (
                <Suspense fallback={null}>
                  <LoginV3 />
                </Suspense>
              ) : (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              )
            }
          />
          <Route
            path="/signup"
            render={(props) =>
              !token ? (
                <Suspense fallback={null}>
                  <SignupV3 />
                </Suspense>
              ) : (
                <Redirect
                  to={{ pathname: "/", state: { from: props.location } }}
                />
              )
            }
          />
          <Route
            path="/"
            render={(props) =>
              token ? (
                <>
                  {/* <Suspense
                    fallback={
                      <div className="loader">
                        <Loader />
                      </div>
                    }
                  > */}
                  <App />
                  {/* </Suspense> */}
                </>
              ) : isTokenLoaded ? (
                <Redirect
                  to={{
                    pathname: "/signin",
                    state: { from: props.location },
                  }}
                />
              ) : (
                <>
                  <Suspense
                    fallback={
                      <div className="loader">
                        <Loader />
                      </div>
                    }
                  >
                    <App />
                  </Suspense>
                </>
              )
            }
          />
        </Switch>
      </>
    ) : (
      ""
    );
  }
}

Root.contextType = RootContext;

export default Root;
