import React from "react";
import { Carousel } from "antd";
import { useTranslation } from "react-i18next";

import carou1 from "../../../../assets/carousel/carou-1.png";
import carou2 from "../../../../assets/carousel/carou-2.png";
import carou5 from "../../../../assets/carousel/carou-5.png";
import carou6 from "../../../../assets/carousel/carou-6.png";

const CarouselLogin = () => {
  const { t } = useTranslation("auth");

  return (
    <Carousel autoplay autoplaySpeed={8000}>
      <div>
        <div className="img-switch">
          <img src={carou6} />
        </div>
        <div className="new-tag">{t("carousel.login_slide1.new_tag")}</div>
        <div className="title">{t("carousel.login_slide1.title")}</div>
        <div className="subtitle">{t("carousel.login_slide1.subtitle")}</div>
      </div>
      <div>
        <div className="img-switch">
          {" "}
          <img src={carou5} />
        </div>
        <div className="new-tag">{t("carousel.login_slide2.new_tag")}</div>
        <div className="title">{t("carousel.login_slide2.title")}</div>
        <div className="subtitle">{t("carousel.login_slide2.subtitle")}</div>
      </div>
      <div>
        <div className="img-switch">
          <img src={carou1} />
        </div>
        <div className="title">{t("carousel.slide1.title")}</div>
        <div className="subtitle">{t("carousel.slide1.subtitle")}</div>
      </div>
    </Carousel>
  );
};

export default CarouselLogin;
