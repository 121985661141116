import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import qs from "query-string";
import { Input, message, Form, Button, Row, Col, Icon, Checkbox } from "antd";
import { hasErrors, verticalFormLayout } from "../../../utils/forms";
import { signup } from "../../../utils/auth";
import logoLR from "../../../assets/logoPlatforms/logo-new-lr.svg";
import "./signup.scss";
import "../login/login.scss";

import PasswordStrengthBar from "react-password-strength-bar";

import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import CarouselSignup from "./Carousels/CarouselSignup";
import CarouselLogos from "./Carousels/CarouselLogos";

export const SignupV3 = Form.create({ name: "Signup" })((props) => {
  const { history, location } = props;
  const { getFieldDecorator, getFieldsError, getFieldValue } = props.form;
  const recaptchaRef = React.useRef();
  const [loadingWhiteLabel, setLoadingWhiteLabel] = useState(true);
  const [email, setEmail] = useState();
  const [first, setFirst] = useState();
  const [last, setLast] = useState();
  const [validationId, setValidationId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [pwScore, setPwScore] = useState();
  const [isWhiteLabel, setIsWhiteLabel] = useState(false);
  const [whiteLabelInfo, setWhiteLabelInfo] = useState();
  const [captchaFilled, setCaptchaFilled] = useState(false);
  const [isCgvChecked, setIsCgvChecked] = useState(false);

  const { t } = useTranslation(["auth", "common"]);

  useEffect(() => {
    const param = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      decoder: (c) => c,
    });
    setValidationId(param.validationId);
    let email;
    if (param.email) {
      if (param.email.includes(" ")) {
        param.email = param.email.replace(" ", "+");
        email = param.email;
      } else {
        email = param.email;
      }
    }
    if (param.first) {
      if (param.first.includes(" ")) {
        param.first = param.first.replace(" ", "+");
        setFirst(param.first);
      } else {
        setFirst(param.first);
      }
    }
    if (param.last) {
      if (param.last.includes(" ")) {
        param.last = param.last.replace(" ", "+");
        setLast(param.last);
      } else {
        setLast(param.last);
      }
    }

    setEmail(email);

    const getWhiteLabelInfo = async () => {
      let data = window.localStorage.getItem("whitelabel");
      if (data) {
        setIsWhiteLabel(true);
        setWhiteLabelInfo(JSON.parse(data));
      }
      setLoadingWhiteLabel(false);
    };

    getWhiteLabelInfo();

    window.analytics.page("/signup");
  }, [location.search]);

  const onSubmit = async () => {
    try {
      const tokenCaptcha = await recaptchaRef.current.executeAsync();
      if (tokenCaptcha) {
        if (isCgvChecked || isWhiteLabel) {
          if (pwScore > 2) {
            setIsLoading(true);
            let defaultLng =
              localStorage.getItem("i18nextLng")?.length > 1
                ? localStorage.getItem("i18nextLng")
                : "fr";

            const user = {
              FirstName: getFieldValue("FirstName"),
              LastName: getFieldValue("LastName"),
              Email: getFieldValue("Email").toLowerCase(),
              Password: getFieldValue("Password"),
              ValidationID: validationId,
              isWhiteLabel,
              Language: defaultLng,
            };

            if (!isWhiteLabel || (isWhiteLabel && validationId)) {
              const { token } = await signup(user);
              if (token) {
                console.debug("Signup local, reloading...");
                if (validationId) {
                  history.push("/signin");
                } else {
                  history.push("/validation");
                  window.analytics.identify({
                    email: user.Email,
                  });
                  if (isWhiteLabel) {
                    window.analytics.track("signup_white_label");
                  } else {
                    window.analytics.track("signup");
                  }
                }
              } else {
                message.warning(t("auth:warning-message.user-exists"));
              }
            } else {
              message.warning(t("auth:warning-message.wrong-domain"));
            }
          } else {
            message.warning(t("auth:warning-message.password"));
          }
        } else {
          message.warning(t("auth:warning-message.cgv"));
        }
      } else {
        message.warning(t("auth:warning-captcha"));
      }
    } catch (err) {
      console.error(err);
      message.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return isWhiteLabel && !loadingWhiteLabel ? (
    <div className="signup-container-v2">
      <div className="block-right-content">
        <div className="welcome-form">
          <div className="heading-signup-new">
            <span className="filson">{t("auth:welcome-new")}</span>
          </div>
          <div className="signup-local">
            <Form {...verticalFormLayout} className="align-left">
              <Row gutter={10}>
                <Col span={12} className="full">
                  <Form.Item
                    className="no-margin"
                    label={t("common:form.firstName")}
                    {...verticalFormLayout}
                  >
                    {getFieldDecorator("FirstName", {
                      rules: [
                        {
                          required: true,
                          // message: "Insérez votre prénom",
                        },
                      ],
                    })(<Input autoFocus placeholder="Jean" />)}
                  </Form.Item>
                </Col>
                <Col span={12} className="full">
                  <Form.Item
                    className="no-margin"
                    label={t("common:form.name")}
                    {...verticalFormLayout}
                  >
                    {getFieldDecorator("LastName", {
                      rules: [
                        {
                          required: true,
                          // message: "Insérez votre nom de famille",
                        },
                      ],
                    })(<Input autoFocus placeholder="Luc" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                className="no-margin"
                label={t("common:form.email")}
                {...verticalFormLayout}
              >
                {getFieldDecorator("Email", {
                  initialValue: email,
                  rules: [
                    {
                      type: "email",
                      // message: "Veuillez insérer votre email",
                    },
                    {
                      required: true,
                      message: t("common:form.warningemail"),
                    },
                  ],
                })(<Input placeholder="jean@airbnb.com" />)}
              </Form.Item>
              <Form.Item
                className="no-margin"
                label={t("common:form.password")}
                {...verticalFormLayout}
              >
                {getFieldDecorator("Password", {
                  rules: [
                    {
                      required: true,
                      message: t("common:form.warningpassword"),
                    },
                  ],
                })(<Input.Password placeholder="*********" type="password" />)}
              </Form.Item>
              <PasswordStrengthBar
                className="password-bar"
                password={getFieldValue("Password")}
                onChangeScore={(score) => {
                  setPwScore(score);
                }}
                scoreWords={[
                  t("common:form.password-strength.veryweak"),
                  t("common:form.password-strength.weak"),
                  t("common:form.password-strength.mid"),
                  t("common:form.password-strength.good"),
                  t("common:form.password-strength.verygood"),
                ]}
                shortScoreWord={t("common:form.password-strength.tooshort")}
              />
              <ReCAPTCHA
                sitekey="6LcLC9UZAAAAAOY_vgaU__i3Qhs1tmHtvbibjD2V"
                onChange={() => {
                  setCaptchaFilled(true);
                }}
                size="invisible"
                ref={recaptchaRef}
              />
              <Form.Item className="no-margin" {...verticalFormLayout}>
                {/* <div className="forgot">
                En vous inscrivant, vous acceptez les
                <a
                  href="https://localranker.fr/conditions-generales-de-vente/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  CGV de LocalRanker.
                </a>
              </div> */}
                <Button
                  onClick={onSubmit}
                  className="signup-button"
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  disabled={
                    !getFieldValue("FirstName") ||
                    !getFieldValue("LastName") ||
                    !getFieldValue("Email") ||
                    !getFieldValue("Password") ||
                    hasErrors(getFieldsError())
                  }
                >
                  {t("auth:signup-now")}
                  <Icon type="arrow-right" />
                </Button>
                <div className="signin-link">
                  <Link to="/signin"> {t("common:button.login-now")}</Link>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className="logo-block-right">
          {whiteLabelInfo && whiteLabelInfo.AppLogo ? (
            <img src={whiteLabelInfo.AppLogo} alt="logo app" width="190px" />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  ) : !loadingWhiteLabel && !isWhiteLabel ? (
    <div className="new-signup">
      <Row>
        <Col xl={12} lg={12} md={24} sm={24}>
          <div className="promo-section">
            <CarouselSignup />
            <div className="promo-footer">{t("auth:trusted-by")}</div>
            <CarouselLogos />
          </div>
        </Col>
        <Col xl={12} lg={12} md={24} sm={24}>
          <div className="form-section">
            <div className="welcome-form">
              <div className="heading-signup-new">
                <div className="logo">
                  {" "}
                  <img src={logoLR} alt="logo local ranker" width="160px" />
                </div>
                <span className="filson">{t("auth:welcome-new")}</span>
                <div style={{ fontSize: "12px", fontWeight: 200 }}>
                  {/* {t("auth:no-cb")} */}
                </div>
              </div>
              <div className="signup-local">
                <Form {...verticalFormLayout} className="align-left">
                  <Row gutter={10}>
                    <Col span={12} className="full">
                      <Form.Item
                        className="no-margin"
                        label={t("common:form.firstName")}
                        {...verticalFormLayout}
                      >
                        {getFieldDecorator("FirstName", {
                          initialValue: first,
                          rules: [
                            {
                              required: true,
                              // message: "Insérez votre prénom",
                            },
                          ],
                        })(<Input autoFocus placeholder="Jean" />)}
                      </Form.Item>
                    </Col>
                    <Col span={12} className="full">
                      <Form.Item
                        className="no-margin"
                        label={t("common:form.name")}
                        {...verticalFormLayout}
                      >
                        {getFieldDecorator("LastName", {
                          initialValue: last,
                          rules: [
                            {
                              required: true,
                              // message: "Insérez votre nom de famille",
                            },
                          ],
                        })(<Input autoFocus placeholder="Luc" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    className="no-margin"
                    label={t("common:form.email")}
                    {...verticalFormLayout}
                  >
                    {getFieldDecorator("Email", {
                      initialValue: email,
                      rules: [
                        {
                          type: "email",
                          // message: "Veuillez insérer votre email",
                        },
                        {
                          required: true,
                          message: t("common:form.warningemail"),
                        },
                      ],
                    })(<Input placeholder="jean@airbnb.com" />)}
                  </Form.Item>

                  <Form.Item
                    className="no-margin"
                    label={t("common:form.password")}
                    {...verticalFormLayout}
                  >
                    {getFieldDecorator("Password", {
                      rules: [
                        {
                          required: true,
                          message: t("common:form.warningpassword"),
                        },
                      ],
                    })(
                      <Input.Password placeholder="*********" type="password" />
                    )}
                  </Form.Item>
                  <PasswordStrengthBar
                    className="password-bar"
                    password={getFieldValue("Password")}
                    onChangeScore={(score) => {
                      setPwScore(score);
                    }}
                    scoreWords={[
                      t("common:form.password-strength.veryweak"),
                      t("common:form.password-strength.weak"),
                      t("common:form.password-strength.mid"),
                      t("common:form.password-strength.good"),
                      t("common:form.password-strength.verygood"),
                    ]}
                    shortScoreWord={t("common:form.password-strength.tooshort")}
                  />

                  <Form.Item className="no-margin" {...verticalFormLayout}>
                    <div>
                      <Checkbox
                        checked={isCgvChecked}
                        onChange={(e) => {
                          setIsCgvChecked(e.target.checked);
                        }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          margin: "0px",
                        }}
                      >
                        <div className="forgot" style={{ marginLeft: "8px" }}>
                          {t("auth:cgv.cgv-part-one")}
                          <a
                            href="https://localranker.fr/conditions-generales-de-vente/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            {t("auth:cgv.cgv-part-two")}
                          </a>
                        </div>
                      </Checkbox>
                    </div>
                    <ReCAPTCHA
                      sitekey="6LcLC9UZAAAAAOY_vgaU__i3Qhs1tmHtvbibjD2V"
                      onChange={() => {
                        setCaptchaFilled(true);
                      }}
                      size="invisible"
                      ref={recaptchaRef}
                    />
                    <Button
                      onClick={onSubmit}
                      type="primary"
                      htmlType="submit"
                      loading={isLoading}
                      disabled={
                        !getFieldValue("FirstName") ||
                        !getFieldValue("LastName") ||
                        !getFieldValue("Email") ||
                        !getFieldValue("Password") ||
                        hasErrors(getFieldsError())
                      }
                    >
                      {t("auth:signup-now")}
                    </Button>
                    <div className="signin-link">
                      <div className="question">
                        {t("auth:account-exists-2")}
                      </div>
                      <div className="link">
                        {" "}
                        <Link to="/signin">
                          {" "}
                          {t("common:button.login-now")}
                        </Link>
                      </div>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  ) : (
    ""
  );
});

export default withRouter(SignupV3);
