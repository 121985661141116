import React from "react";

import logos from "../../../../assets/carousel/logo-signup.png";

const CarouselLogos = () => {
  return (
    <div className="carousel-logos">
      <img src={logos} />
    </div>
  );
};

export default CarouselLogos;
