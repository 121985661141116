import React from "react";
import { Carousel } from "antd";
import { useTranslation } from "react-i18next";

import carou1 from "../../../../assets/carousel/carou-1.png";
import carou2 from "../../../../assets/carousel/carou-2.png";
import carou3 from "../../../../assets/carousel/carou-3.png";
import carou4 from "../../../../assets/carousel/carou-4.png";

const CarouselSignup = () => {
  const { t } = useTranslation("auth");

  return (
    <Carousel autoplay autoplaySpeed={8000}>
      <div>
        <div className="img-switch">
          <img src={carou1} alt="carou 1" />
        </div>
        <div className="title">{t("carousel.slide1.title")}</div>
        <div className="subtitle">{t("carousel.slide1.subtitle")}</div>
      </div>
      <div>
        <div className="img-switch">
          {" "}
          <img src={carou2} alt="carou 2" />
        </div>
        <div className="title">{t("carousel.slide2.title")}</div>
        <div className="subtitle">{t("carousel.slide2.subtitle")}</div>
      </div>
      <div>
        <div className="img-switch">
          {" "}
          <img src={carou3} alt="carou 3" />
        </div>
        <div className="title">{t("carousel.slide3.title")}</div>
        <div className="subtitle">{t("carousel.slide3.subtitle")}</div>
      </div>
      <div>
        <div className="img-switch">
          {" "}
          <img src={carou4} alt="carou 4" />
        </div>
        <div className="title">{t("carousel.slide4.title")}</div>
        <div className="subtitle">{t("carousel.slide4.subtitle")}</div>
      </div>
    </Carousel>
  );
};

export default CarouselSignup;
