import React from "react";
import bugImg from "./assets/bug.svg";
import "./index.scss";
import { useTranslation } from "react-i18next";
const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const { t } = useTranslation("common");
  return (
    <div className="error-page">
      <div role="alert">
        <p className="error-message">
          Oh-oh{" "}
          <span role="img" aria-label="emoji">
            😱
          </span>
          {t("error-occured")} <br />
          {t("refresh-page")}
        </p>
        <img src={bugImg} alt="bug" width="500px" />
        {/* <pre>{error.message}</pre> */}
        {/* <button onClick={resetErrorBoundary}>Try again</button> */}
      </div>
    </div>
  );
};

export default ErrorFallback;
