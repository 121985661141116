import React, { useEffect, useState } from "react";

import loader from "../../assets/loader-star.svg";
import loaderGif from "../../assets/loader-logo.gif";
import "./loader.css";

const Loader = () => {
  const [isWhiteLabel, setIsWhiteLabel] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getWhiteLabelInfo = async () => {
      let data = window.localStorage.getItem("whitelabel");
      if (data) {
        setIsWhiteLabel(true);
      }
      setLoading(false);
    };
    getWhiteLabelInfo();

    // eslint-disable-next-line
  }, []);
  if (loading) {
    return <></>;
  } else {
    return (
      <div className={isWhiteLabel ? "loader-star white-label" : "loader-star"}>
        {isWhiteLabel ? (
          <img src={loader} alt="loader" />
        ) : (
          <img src={loaderGif} alt="loader" />
        )}
      </div>
    );
  }
};

export default Loader;
