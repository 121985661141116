import { fetchApi, fetchApiFile } from "./utils";

export const currentAccount = async (force) => {
  let account = {};
  try {
    account = JSON.parse(localStorage.getItem("account"));
  } catch (e) {
    console.error(e);
    window.localStorage.removeItem("account");
  }
  if (!account || force) {
    try {
      const accounts = await getMyAccounts();
      account = accounts.Accounts[0];
      window.localStorage.setItem("account", JSON.stringify(account));
    } catch (e) {
      /* ignore if there is no user */
      console.debug(e);
    }
  }
  return account;
};

/**
 * Get accounts by a user
 */

export const getMyAccounts = async () =>
  fetchApi("/accounts-for-user/me", { method: "GET" });

export const addNewAccount = async (account) =>
  fetchApi("/accounts-for-user/me", {
    addAccountId: true,
    method: "PUT",
    params: { account },
  });
export const updateAccountName = async (accountID, accountName, timezone) =>
  fetchApi("/account", {
    method: "PUT",
    params: { accountID, accountName, timezone },
  });

export const changePagination = async (pagination) =>
  fetchApi("/change-pagination", { method: "PUT", params: { pagination } });

export const changeDefaultBusinessView = async (view) =>
  fetchApi("/change-default-view", { method: "POST", params: { view } });

export const uploadProfileImageToAWS = async (formData) => {
  let imageUrl = await fetchApiFile("/profile-image", {
    method: "POST",
    params: formData,
  });
  return imageUrl;
};

export const deleteProfilePictureFromAccount = async (imageUrl) =>
  fetchApi(`/profile-image?imageUrl=${imageUrl}`, {
    method: "DELETE",
  });

export const updateWhiteLabelSettings = async (data) =>
  fetchApi("/white-label-settings", {
    method: "PUT",
    params: {
      Primary: data.Primary,
      Secondary: data.Secondary,
      MetaTitle: data.MetaTitle,
      MetaDescription: data.MetaDescription,
      AppName: data.AppName,
    },
  });

export const fetchWhiteLabelSettings = async () =>
  fetchApi("/white-label-settings", { method: "GET" });

export const fetchWhiteLabelSettingsByDomain = async (domain) =>
  fetchApi("/white-label-settings-domain", {
    method: "GET",
    params: { domain },
  });

export const fetchAccountsByEmail = async (searchParam) =>
  fetchApi("/superuser/fetch-accounts", {
    method: "GET",
    params: { searchParam },
  });

export const impersonateAccountSU = async (userSelected, accountSelected) =>
  fetchApi("/superuser/impersonate-account", {
    method: "PUT",
    params: { userSelected, accountSelected },
  });

export const editAccountSU = async (data, accountSelected) =>
  fetchApi("/superuser/edit-account", {
    method: "PUT",
    params: { data, accountSelected },
  });

export const sendOnboardingInfo = async (data) =>
  fetchApi("/onboarding-info", {
    method: "POST",
    params: { data },
  });

export const getUpdatesGoogleRefused = async () =>
  fetchApi("/google-updates-refused", {
    method: "GET",
  });

export const getAISettings = async () =>
  fetchApi("/ai-answer-settings", {
    method: "GET",
  });

export const updateAISettings = async (data) =>
  fetchApi("/ai-answer-settings", {
    method: "PUT",
    params: {
      ...data,
    },
  });

export const refreshApiKey = async () =>
  fetchApi("/refresh-api-key", {
    method: "PUT",
  });

export const getApiInfo = async () =>
  fetchApi("/api-info", {
    method: "GET",
  });
