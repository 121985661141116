import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import "./login.scss";
import { Link, withRouter } from "react-router-dom";
import { Input, message, Form, Icon, Button, Row, Col } from "antd";
import logoLR from "../../../assets/logoPlatforms/logo-new-lr.svg";
import { extractParams, getToken } from "../../../utils/utils";
import { RootContext } from "../../../RootContextProvider";
import { hasErrors, verticalFormLayout } from "../../../utils/forms";
import { signin } from "../../../utils/auth";

import CarouselLogin from "../signup/Carousels/CarouselLogin";

export const Login = Form.create({ name: "Signin" })((props) => {
  const { history } = props;
  const { getFieldDecorator, getFieldsError, getFieldValue } = props.form;
  const [loadingWhiteLabel, setLoadingWhiteLabel] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isWhiteLabel, setIsWhiteLabel] = useState(false);
  const [whiteLabelInfo, setWhiteLabelInfo] = useState();
  const { setToken } = useContext(RootContext);
  const { t } = useTranslation(["auth", "common"]);

  useEffect(() => {
    const getSigninMethod = async () => {
      const param = await extractParams(props);
      if (param) {
        if (param.method === "login") {
          // window.analytics.track('login-google')
        } else if (param.method === "signup") {
          // window.analytics.track('signup-google')
        }
      }
    };
    getSigninMethod().then(() => {
      getUserToken();
    });

    const getUserToken = async () => {
      await getToken(props, setToken);
    };

    const getWhiteLabelInfo = async () => {
      let data = window.localStorage.getItem("whitelabel");
      if (data) {
        setIsWhiteLabel(true);
        console.log(JSON.parse(data));
        setWhiteLabelInfo(JSON.parse(data));
      }
      setLoadingWhiteLabel(false);
    };
    getWhiteLabelInfo();

    // eslint-disable-next-line
  }, []);

  const handleOnSubmit = async () => {
    setIsLoading(true);
    try {
      const values = {
        Email: getFieldValue("Email").toLowerCase(),
        Password: getFieldValue("Password"),
      };
      const cb = await signin(values);
      // console.log(cb);
      if (cb.err === "Please confirm your email.") {
        console.debug("Validation needed, reloading...");
        setIsLoading(false);
        history.push("/validation");
      } else if (!cb.token) {
        message.error(cb.err);
        setIsLoading(false);
      } else {
        window.analytics.identify(cb._id, {
          email: cb?.email,
          access: cb?.Access,
          language: cb?.Language,
          firstName: cb?.FirstName,
          lastName: cb?.LastName,
        });
        if (!cb.email.includes("+impersonate@localranker.fr")) {
          window.analytics.group(cb.AccountID, {
            name: cb?.AccountName,
            isPaying: cb?.IsPaying,
            plan: cb?.Plan,
            nbBusinesses: cb?.NbBusinesses,
            isWhiteLabel: cb?.IsWhiteLabel,
            creationDate: cb?.CreationDate,
          });
        }
        if (isWhiteLabel) {
          window.analytics.track("login-white-label");
        } else {
          window.analytics.track("login");
        }
        setIsLoading(false);
        setToken(cb.token, true);
      }
    } catch (err) {
      console.error(err);
      message.error(err.message);
      setIsLoading(false);
    }
  };

  return !loadingWhiteLabel && isWhiteLabel ? (
    <div className="signup-container-v2">
      <div className="block-right-content">
        <div className="welcome-form">
          {" "}
          <div className="heading-signup-new">
            <span className="filson">{t("welcomeback")}</span>
          </div>
          <div className="signup-local">
            <Form className="form-signup align-left" {...verticalFormLayout}>
              <Form.Item label="Email" {...verticalFormLayout}>
                {getFieldDecorator("Email", {
                  rules: [
                    {
                      type: "email",
                      message: "Veuillez insérer un email valide",
                    },
                    {
                      required: true,
                      message: "Veuillez insérer votre email. ",
                    },
                  ],
                })(<Input placeholder="jean@airbnb.com" />)}
              </Form.Item>

              <Form.Item
                label={t("common:form.password")}
                {...verticalFormLayout}
              >
                {getFieldDecorator("Password", {
                  rules: [
                    {
                      required: true,
                      message: "Veuillez insérer votre mot de passe",
                    },
                  ],
                })(<Input.Password placeholder="*********" type="password" />)}
              </Form.Item>

              <Form.Item {...verticalFormLayout}>
                <div className="forgot">
                  <Link to="/forgot-password">{t("auth:forgotpassword")}</Link>
                </div>

                <Button
                  onClick={handleOnSubmit}
                  className="signup-button"
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  disabled={
                    !getFieldValue("Email") ||
                    !getFieldValue("Password") ||
                    hasErrors(getFieldsError())
                  }
                >
                  {t("common:button.login")} <Icon type="arrow-right" />
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className="logo-block-right">
          {whiteLabelInfo && whiteLabelInfo.AppLogo ? (
            <img src={whiteLabelInfo.AppLogo} alt="logo app" width="190px" />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  ) : !loadingWhiteLabel && !isWhiteLabel ? (
    <div className="new-signup login">
      <Row>
        <Col xl={12} lg={12} md={24} sm={24}>
          <div className="promo-section">
            <CarouselLogin />
            <div className="promo-footer">{t("auth:trusted-by")}</div>
          </div>
        </Col>
        <Col xl={12} lg={12} md={24} sm={24}>
          <div className="signup-container-v2">
            <div className="welcome-form">
              {" "}
              <div className="heading-signup-new">
                <div className="logo">
                  {" "}
                  <img src={logoLR} alt="logo local ranker" width="160px" />
                </div>
                <span className="filson">{t("welcomeback")}</span>
              </div>
              <div className="signup-local">
                <Form
                  className="form-signup align-left"
                  {...verticalFormLayout}
                >
                  <Form.Item label="Email" {...verticalFormLayout}>
                    {getFieldDecorator("Email", {
                      rules: [
                        {
                          type: "email",
                          message: "Veuillez insérer un email valide",
                        },
                        {
                          required: true,
                          message: "Veuillez insérer votre email. ",
                        },
                      ],
                    })(<Input placeholder="jean@airbnb.com" />)}
                  </Form.Item>

                  <Form.Item
                    label={t("common:form.password")}
                    {...verticalFormLayout}
                  >
                    {getFieldDecorator("Password", {
                      rules: [
                        {
                          required: true,
                          message: "Veuillez insérer votre mot de passe",
                        },
                      ],
                    })(
                      <Input.Password placeholder="*********" type="password" />
                    )}
                  </Form.Item>

                  <Form.Item {...verticalFormLayout}>
                    <Button
                      onClick={handleOnSubmit}
                      //   className="signup-button"
                      type="primary"
                      htmlType="submit"
                      loading={isLoading}
                      disabled={
                        !getFieldValue("Email") ||
                        !getFieldValue("Password") ||
                        hasErrors(getFieldsError())
                      }
                    >
                      {t("common:button.login-now")}
                    </Button>
                  </Form.Item>
                </Form>
                <div className="forgot">
                  <Link to="/forgot-password">{t("auth:forgotpassword")}</Link>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  ) : (
    ""
  );
});

Login.contextType = RootContext;

export default withRouter(Login);
