import React, { useState, useEffect } from "react";
import { Icon, Skeleton, Button, message, Row, Col } from "antd";
import qs from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import "./AccountValidation.css";
import { verifyEmail } from "../../../utils/auth";

import { useTranslation } from "react-i18next";
import CarouselLogin from "../signup/Carousels/CarouselLogin";

const AccountValidationV2 = () => {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [displayFailure, setDisplayFailure] = useState(false);
  const [displayCheckInbox, setDisplayCheckInbox] = useState(true);
  const { t } = useTranslation(["auth", "common"]);

  useEffect(() => {
    const verifyEmail = async () => {
      setLoading(true);
      const id = qs.parse(location.search);
      console.log(id);
      if (Object.entries(id).length !== 0) {
        setDisplayCheckInbox(false);
        // API CALL TO ENDPOINTS TO VALIDATE EMAIL
        confirmEmail(id.id);
      } else {
        setLoading(false);
      }
    };
    verifyEmail();
  }, []);

  const confirmEmail = async (id) => {
    const verification = await verifyEmail(id);
    console.log(verification);
    if (verification.responseDB === true) {
      setDisplaySuccess(true);
      setLoading(false);
    } else if (verification.responseDB === "Email Already Validated!") {
      setDisplaySuccess(true);
      setLoading(false);
      message.warning(verification.responseDB);
    } else {
      setDisplayFailure(true);
      setLoading(false);
      message.warning(verification.responseDB);
    }
  };

  const handleClearCookie = () => {
    window.localStorage.clear();
    console.debug("Signing, reloading...");
    history.push("/signin");
  };

  return (
    <div className="new-signup login">
      <Row>
        <Col xl={12} lg={12} md={24} sm={24}>
          <div className="promo-section">
            <CarouselLogin />
            <div className="promo-footer">{t("auth:trusted-by")}</div>
          </div>
        </Col>
        <Col xl={12} lg={12} md={24} sm={24}>
          <div className="block-right-content">
            <div className="welcome-form">
              {" "}
              <div className="signup-local">
                <div
                  className="modal-body"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  {loading && <Skeleton active />}

                  {displayCheckInbox && !loading && (
                    <>
                      <div className="main-sentence-body">
                        <h2 className="filson">
                          {" "}
                          <span role="img" aria-label="emoji">
                            💌
                          </span>{" "}
                          {t("auth:check-inbox")}
                        </h2>
                      </div>
                      <br />
                      <p>{t("auth:email-sent")}</p>
                      <div className="hr-line" />
                      <div className="hr-line-validation">
                        <div>{t("auth:account-exists")}</div>
                      </div>
                    </>
                  )}
                  {displaySuccess && !loading && (
                    <>
                      <h2 className="main-sentence-body filson">
                        <b>
                          <span role="img" aria-label="emoji">
                            ✅
                          </span>{" "}
                          {t("auth:account-validated")}
                        </b>
                      </h2>
                      <br />
                      <p>{t("auth:connect-platform")}</p>
                    </>
                  )}
                  {displayFailure && !loading && (
                    <h2 className="main-sentence-body filson">
                      <b>
                        <span role="img" aria-label="emoji">
                          ❌
                        </span>{" "}
                        Oh-oh! {t("auth:failed-validation")}
                      </b>
                    </h2>
                  )}

                  <Button
                    className="signup-button-valid"
                    type="primary"
                    htmlType="submit"
                    onClick={handleClearCookie}
                  >
                    {t("common:button.login-now")} <Icon type="arrow-right" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AccountValidationV2;
