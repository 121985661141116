import React, { useState, useEffect } from "react";
import { Input, Button, Row, Col } from "antd";

import { forgot } from "../../../utils/auth";
import "./forgot.css";

import { useTranslation } from "react-i18next";
import CarouselLogin from "../signup/Carousels/CarouselLogin";
import logoLR from "../../../assets/logoPlatforms/logo-new-lr.svg";

const ForgotV2 = () => {
  const [loadingWhiteLabel, setLoadingWhiteLabel] = useState(true);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState();
  const [emailSent, setEmailSent] = useState(false);
  const [isWhiteLabel, setIsWhiteLabel] = useState(false);
  const [whiteLabelInfo, setWhiteLabelInfo] = useState();
  const { t } = useTranslation(["auth"]);

  const onSubmit = async () => {
    setLoading(true);
    await forgot(email);
    setEmailSent(true);
    setLoading(false);
  };

  useEffect(() => {
    const getWhiteLabelInfo = async () => {
      let data = window.localStorage.getItem("whitelabel");
      if (data) {
        setIsWhiteLabel(true);
        console.log(JSON.parse(data));
        setWhiteLabelInfo(JSON.parse(data));
      }
      setLoadingWhiteLabel(false);
    };
    getWhiteLabelInfo();
  }, []);

  return isWhiteLabel ? (
    <div className="signup-container-v2">
      <div className="block-right-content">
        <div className="welcome-form">
          {" "}
          <div className="heading-signup-new">
            <span>{t("auth:forgot")}</span>
          </div>
          <div className="signup-local">
            {!emailSent && (
              <>
                <Input
                  style={{ marginBottom: 0 }}
                  autoFocus
                  type="email"
                  placeholder="jean@airbnb.com"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />

                <Button
                  className="signup-button-valid"
                  type="primary"
                  disabled={loading || !email}
                  onClick={onSubmit}
                  icon="arrow-right"
                >
                  {t("auth:cta-forgot")}
                </Button>
              </>
            )}
            {emailSent && (
              <>
                <span className="success-reset">
                  <div
                    role="img"
                    aria-label="emoji"
                    style={{ marginRight: "8px" }}
                  >
                    ✅
                  </div>
                  {t("auth:email-sent-forgot")}
                </span>
              </>
            )}
          </div>
        </div>
        <div className="logo-block-right">
          {whiteLabelInfo && whiteLabelInfo.AppLogo ? (
            <img src={whiteLabelInfo.AppLogo} alt="logo app" width="190px" />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="new-signup login">
      <Row>
        <Col xl={12} lg={12} md={24} sm={24}>
          <div className="promo-section">
            <CarouselLogin />
            <div className="promo-footer">{t("auth:trusted-by")}</div>
          </div>
        </Col>
        <Col xl={12} lg={12} md={24} sm={24}>
          <div className="signup-container-v2">
            <div className="welcome-form">
              {" "}
              <div className="heading-signup-new">
                <div className="logo">
                  {" "}
                  <img
                    src={logoLR}
                    alt="logo local ranker"
                    width="160px"
                    style={{ marginBottom: "60px" }}
                  />
                </div>
                <span>{t("auth:forgot")}</span>
              </div>
              <div className="signup-local">
                {!emailSent && (
                  <>
                    <Input
                      style={{ marginBottom: 0 }}
                      autoFocus
                      type="email"
                      placeholder="jean@airbnb.com"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />

                    <Button
                      className="signup-button-valid"
                      type="primary"
                      disabled={loading || !email}
                      onClick={onSubmit}
                    >
                      {t("auth:cta-forgot")}
                    </Button>
                  </>
                )}
                {emailSent && (
                  <>
                    <span className="success-reset">
                      <span role="img" aria-label="emoji">
                        ✅
                      </span>
                      <div style={{ maxWidth: "800px" }}>
                        {t("auth:email-sent-forgot")}
                        {/* <span></span> */}
                      </div>
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ForgotV2;
