import { fetchApi } from "./utils";

/**
 * Sign a user in
 * @param {string} email      user email
 * @param {string} password   user password
 */
export const signin = async ({ Email, Password }) =>
  fetchApi("/auth/signin", { method: "POST", params: { Email, Password } });

/**
 * Sign a new user up
 * @param  {String} Email      REQUIRED email
 * @param  {String} password   REQUIRED password
 */
export const signup = async ({
  Email,
  Password,
  FirstName,
  LastName,
  ValidationID,
  isWhiteLabel,
  Language,
}) =>
  fetchApi("/auth/signup", {
    method: "POST",
    params: {
      Email,
      Password,
      FirstName,
      LastName,
      ValidationID,
      isWhiteLabel,
      Language,
    },
  });

/**
 * Sign a new user up
 * @param  {String} email      REQUIRED email
 * @param  {String} password   REQUIRED password
 */
export const signupCurrentUser = ({
  Email,
  Password,
  FirstName,
  LastName,
  ValidationID,
}) =>
  fetchApi("/auth/signup/current-user", {
    method: "POST",
    params: { Email, Password, FirstName, LastName, ValidationID },
  });

/**
 * Requires forgot email password
 * @param {string} email      user email
 */
export const forgot = (Email) =>
  fetchApi("/auth/forgot", { method: "POST", params: { Email } });

/**
 * Reset password for unauthentified user
 * @param {string} token      token sent by the API
 * @param {string} password   new user password
 */
export const reset = (Token, Password) =>
  fetchApi("/auth/reset", { method: "POST", params: { Token, Password } });

/**
 * Double optin endpoint call
 */

export const verifyEmail = (id) =>
  fetchApi(`/auth/validation/${id}`, { method: "POST" });

/**
 * Get unauthentified user by VerificationID
 */
export const getUserByVerificationID = (id) =>
  fetchApi(`/auth/verification/${id}`, { method: "GET" });
